<template>
  <div>
    <v-container fluid>
      <v-card>
        <v-toolbar flat color="white">
          <v-text-field filled flat single-line hide-details dense clearable rounded v-model="busca" max-width="300px" prepend-inner-icon="search" label="Buscar COFINS"></v-text-field>
          <v-spacer></v-spacer>
          <v-btn text icon :loading="carregando" @click="carregarCofins">
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
          <v-btn color="primary" @click="novo" data-cy="novo">Novo COFINS</v-btn>
        </v-toolbar>
        <v-divider></v-divider>
        <v-data-table :headers="headers" :items="cofins" :search="busca" :loading="carregando">
          <template v-slot:item="props">
            <tr>
              <td>{{ props.item.codigo }}</td>
              <td>{{ props.item.descricao }}</td>
              <td class="text-center">
                <v-menu left transition="slide-x-transition">
                  <template v-slot:activator="{ on }">
                    <v-btn icon v-on="on">
                      <v-icon>more_vert</v-icon>
                    </v-btn>
                  </template>
                  <v-list dense>
                    <v-list-item :key="props.item._id + 20" @click="editar(props.item)">
                      <v-list-item-icon><v-icon>edit</v-icon></v-list-item-icon>
                      <v-list-item-title>Editar</v-list-item-title>
                    </v-list-item>
                    <v-list-item :key="props.item._id + 40" @click="remover(props.item)">
                      <v-list-item-icon><v-icon>delete</v-icon></v-list-item-icon>
                      <v-list-item-title>Remover</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
    </v-container>
</div>
</template>

<script>

  import {mapState, mapGetters, mapActions} from 'vuex';
  import constantes from '@/util/constantes';

  export default {
    data () {
      return {
        headers: [
          { text: 'Código', value: 'codigo' },
          { text: 'Descrição', value: 'descricao' },
          { text: 'Ações', value: 'name', align:'center',  sortable: false }
        ],
        constantes: constantes,
        busca: ''
      }
    },

    computed: {
      ...mapState('cofins', {
        cofins: 'cofins',
        carregando: 'carregando'
      })
    },

    methods: {

      async carregarCofins(){
        await this.$store.dispatch('cofins/carregar');
      },

      editar(cofins){
        this.$store.commit('cofins/editar', cofins);
        this.$router.push({name: 'formularioCOFINSEditar'});
      },

      novo(){
        this.$store.commit('cofins/novo');
        this.$router.push({name: 'formularioCOFINSNovo'});
      },

      remover(cofins){

        this.$root.$confirmacao.open('Remover', 'Tem certeza que deseja remover?')
        .then((confirmado) => {
          if(confirmado){
            this.$store.dispatch('cofins/remover', cofins);
          }
        });
      }
    },

    created(){
      this.carregarCofins();
    }
  }
</script>